import { Suspense } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { MemberOrganizationList } from "@/components/organization/MemberOrganization";
import { OpenlyListedOrganizationList } from "@/components/organization/OpenlyListedOrganization";
import { Layout, NavigationHeader } from "@/components/uikit";
import type { pagesQuery } from "@/queries/__generated__/pagesQuery.graphql";

const PagesQuery = graphql`
  query pagesQuery {
    viewer {
      ...MemberOrganization_organizationListQuery
      ...OpenlyListedOrganization_organizationListQuery
    }
  }
`;

const Content = () => {
  const query = useLazyLoadQuery<pagesQuery>(
    PagesQuery,
    {},
    { fetchPolicy: "store-and-network" }
  );

  return (
    <Layout.Column className="col-span-full">
      {query.viewer && <MemberOrganizationList queryRef={query.viewer} />}
      {query.viewer && <OpenlyListedOrganizationList queryRef={query.viewer} />}
    </Layout.Column>
  );
};

const IndexPage = () => {
  return (
    <Layout.Main>
      <NavigationHeader />
      <Layout.Grid className="py-12">
        <Suspense fallback="...">
          <Content />
        </Suspense>
      </Layout.Grid>
    </Layout.Main>
  );
};

export default IndexPage;
