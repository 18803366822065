export type Nullable<T> = {
  [K in keyof T]: T[K] | null;
};

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type RequiredBy<T, K extends keyof T> = Omit<T, K> &
  Required<Pick<T, K>>;

export function invariant<T>(
  condition: T,
  message = "Invariant failed."
): asserts condition {
  if (condition) {
    return;
  }
  throw new Error(message);
}
