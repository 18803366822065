import { mapValues } from "lodash";

export function replaceEmptyStringWithNull<T extends object>(obj: T) {
  return mapValues(obj, (value: any) =>
    typeof value === "string" && value.length === 0 ? null : value
  ) as T;
}

type ReplaceNullWithUndefined<T extends object, TKey extends keyof T> = Omit<
  T,
  TKey
> & {
  [K in TKey]: Exclude<T[K], null>;
};

export function replaceNullWithUndefined<T extends object>(
  obj: T,
  keys = Object.keys(obj) as (keyof T)[]
) {
  return mapValues(obj, (value: any, key: keyof T) =>
    keys.includes(key) && value === null ? undefined : value
  ) as ReplaceNullWithUndefined<T, (typeof keys)[number]>;
}
