import { useCallback } from "react";
import { ConnectionHandler, Variables } from "react-relay";

import { invariant } from "@/lib/types";

function useConnectionIds() {
  const getConnectionId = useCallback(
    <F extends Variables | null | undefined>({
      key,
      defaultFilters,
    }: {
      key: string;
      defaultFilters?: F;
    }) => {
      return (nodeId: string | string[] | null | undefined, filters?: F) => {
        invariant(
          typeof nodeId === "string",
          `Connection "${key}" passed a nodeId of unexpected type "${typeof nodeId}": "${nodeId}`
        );
        return ConnectionHandler.getConnectionID(
          nodeId,
          key,
          filters ?? defaultFilters
        );
      };
    },
    []
  );
  return { getConnectionId };
}

export const useUserConnectionIds = () => {
  const { getConnectionId } = useConnectionIds();
  return {
    organizationUsersConnectionId: getConnectionId({
      key: "User_organizationUsers",
    }),
    personalAccessTokensConnectionId: getConnectionId({
      key: "User_personalAccessTokens",
    }),
    openlyListedOrganizationsConnectionId: getConnectionId({
      key: "User_openlyListedOrganizations",
    }),
  };
};

export const useOrganizationConnectionIds = () => {
  const { getConnectionId } = useConnectionIds();
  return {
    billingGroupsConnectionId: getConnectionId({
      key: "Organization_billingGroups",
    }),
    fhirServersConnectionId: getConnectionId({
      key: "Organization_fhirServers",
    }),
    studiesConnectionId: getConnectionId({
      key: "Organization_studies",
    }),
    organizationUsersConnectionId: getConnectionId({
      key: "Organization_organizationUsers",
    }),
    accessLinksConnectionId: getConnectionId({
      key: "Organization_accessLinks",
      defaultFilters: {
        filter: { status: "ACTIVE", isOpenListingLink: false },
      },
    }),
    accessRequestsConnectionId: getConnectionId({
      key: "Organization_accessRequests",
      defaultFilters: { filter: { status: "PENDING_APPROVAL" } },
    }),
  };
};

export const useStudyConnectionIds = () => {
  const { getConnectionId } = useConnectionIds();
  return {
    credentialsConnectionId: getConnectionId({
      key: "Study_credentials",
    }),
    fhirVersionsConnectionId: getConnectionId({
      key: "Study_fhirVersions",
    }),
    latestFhirVersionConnectionId: getConnectionId({
      key: "Study_latestFhirVersion",
    }),
    studyFhirServersConnectionId: getConnectionId({
      key: "Study_studyFhirServers",
    }),
    studyUsersConnectionId: getConnectionId({
      key: "Study_studyUsers",
    }),
    eligibleStudyUsersConnectionId: getConnectionId({
      key: "Study_eligibleStudyUsers",
    }),
    volumesConnectionId: getConnectionId({
      key: "Study_volumes",
    }),
    mostRecentResourceJobsConnectionId: getConnectionId({
      key: "Study_mostRecentResourceJobs",
    }),
  };
};

export const useVolumeConnectionIds = () => {
  const { getConnectionId } = useConnectionIds();
  return {
    volumeJobInProgressConnectionId: getConnectionId({
      key: "Volume_volumeJobInProgress",
      defaultFilters: {
        filter: {
          isComplete: false,
          operation: ["VOLUME_HASH", "VOLUME_LIST", "VOLUME_LIST_AND_HASH"],
        },
      },
    }),
  };
};

export const useFhirServerConnectionIds = () => {
  const { getConnectionId } = useConnectionIds();
  return {
    studyFhirServersConnectionId: getConnectionId({
      key: "FhirServer_studyFhirServers",
    }),
  };
};

export const useStudyFhirServerConnectionIds = () => {
  const { getConnectionId } = useConnectionIds();
  return {
    studyFhirServerJobsConnectionId: getConnectionId({
      key: "StudyFhirServer_jobs",
    }),
    studyFhirServerMostRecentCompleteReleaseJobConnectionId: getConnectionId({
      key: "StudyFhirServer_mostRecentCompleteReleaseJob",
      defaultFilters: {
        filter: { operation: ["FHIR_RELEASE"], isComplete: true },
      },
    }),
    studyFhirServerFhirVersionsConnectionId: getConnectionId({
      key: "StudyFhirServer_fhirVersions",
    }),
  };
};
