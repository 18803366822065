import { Globe, Mail, Search } from "lucide-react";
import Link from "next/link";
import { graphql, useFragment } from "react-relay";

import { Avatar, AvatarFallback, AvatarImage } from "@dewrangle/ui";

import { Layout, List } from "@/components/uikit";
import { OpenlyListedOrganization_organizationCardQuery$key } from "@/queries/__generated__/OpenlyListedOrganization_organizationCardQuery.graphql";
import { OpenlyListedOrganization_organizationListQuery$key } from "@/queries/__generated__/OpenlyListedOrganization_organizationListQuery.graphql";

const useOpenlyListedOrganizationCardFragment = (
  queryRef: OpenlyListedOrganization_organizationCardQuery$key
) => {
  return useFragment(
    graphql`
      fragment OpenlyListedOrganization_organizationCardQuery on Organization {
        id
        name
        image
        description
        website
        email
      }
    `,
    queryRef
  );
};

const OpenlyListedOrganizationCard = ({
  queryRef,
}: {
  queryRef: OpenlyListedOrganization_organizationCardQuery$key;
}) => {
  const query = useOpenlyListedOrganizationCardFragment(queryRef);

  return (
    <List.ListItem key={query.id} className="!flex-col">
      <Layout.Column className="w-full">
        <Layout.Row className="justify-between items-start">
          <Layout.Row className="items-center">
            <Avatar className="h-8 w-8">
              <AvatarImage
                src={query.image || undefined}
                alt="organization logo"
              />
              <AvatarFallback>{query.name[0]}</AvatarFallback>
            </Avatar>
            <div className="font-heading text-xl">
              <Link
                href={{
                  pathname: "/[organization]",
                  query: { organization: query.id },
                }}
                className="hocus:underline"
              >
                {query.name}
              </Link>
            </div>
          </Layout.Row>
        </Layout.Row>
        <Layout.Row className="gap-x-6">
          {query.website && (
            <Layout.Row className="gap-x-2">
              <Globe size={16} className="text-muted-foreground" />
              <Layout.Row className="text-sm gap-x-1">
                <p>{query.website}</p>
              </Layout.Row>
            </Layout.Row>
          )}
          {query.email && (
            <Layout.Row className="gap-x-2">
              <Mail size={16} className="text-muted-foreground" />
              <Layout.Row className="text-sm gap-x-1">
                <p>{query.email}</p>
              </Layout.Row>
            </Layout.Row>
          )}
        </Layout.Row>
      </Layout.Column>
    </List.ListItem>
  );
};

// "Public" is misleading. "Visible" is true, but so are memebr organizations
// "Openly-Listed" is heavy but accurate?

export const useOpenlyListedOrganizationListFragment = (
  queryRef: OpenlyListedOrganization_organizationListQuery$key
) => {
  return useFragment(
    graphql`
      fragment OpenlyListedOrganization_organizationListQuery on User {
        openlyListedOrganizations(first: 999)
          @connection(key: "User_openlyListedOrganizations") {
          edges {
            node {
              id
              ...OpenlyListedOrganization_organizationCardQuery
            }
          }
        }
      }
    `,
    queryRef
  );
};

export const OpenlyListedOrganizationList = ({
  queryRef,
}: {
  queryRef: OpenlyListedOrganization_organizationListQuery$key;
}) => {
  const query = useOpenlyListedOrganizationListFragment(queryRef);
  return query.openlyListedOrganizations.edges.length > 0 ? (
    <>
      <Layout.Row className="justify-between">
        <Layout.Row>
          <Search size={32} className="text-muted-foreground stroke-[1.5]" />
          <h2>Openly-Listed Organizations</h2>
        </Layout.Row>
      </Layout.Row>
      <List.List className="bg-background">
        {query.openlyListedOrganizations.edges.map(({ node }) => (
          <OpenlyListedOrganizationCard
            data-test-id={`organization-card-${node.id}`}
            key={node.id}
            queryRef={node}
          />
        ))}
      </List.List>
    </>
  ) : null;
};
