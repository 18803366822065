import { Boxes, FolderOpen, Globe, User } from "lucide-react";
import Link from "next/link";
import { graphql, useFragment } from "react-relay";

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Badge,
  Card,
  CardFooter,
  CardHeader,
} from "@dewrangle/ui";

import { OrganizationCreateDialog } from "@/components/organization/Forms";
import { Layout, List } from "@/components/uikit";
import type { MemberOrganization_organizationCardQuery$key } from "@/queries/__generated__/MemberOrganization_organizationCardQuery.graphql";
import type { MemberOrganization_organizationListQuery$key } from "@/queries/__generated__/MemberOrganization_organizationListQuery.graphql";

const useMemberOrganizationCardFragment = (
  queryRef: MemberOrganization_organizationCardQuery$key
) => {
  return useFragment(
    graphql`
      fragment MemberOrganization_organizationCardQuery on Organization {
        id
        name
        image
        organizationUser {
          role
        }
        studies {
          totalCount
        }
        organizationUsers {
          totalCount
        }
      }
    `,
    queryRef
  );
};

const MemberOrganizationCard = ({
  queryRef,
}: {
  queryRef: MemberOrganization_organizationCardQuery$key;
}) => {
  const query = useMemberOrganizationCardFragment(queryRef);
  return (
    <List.ListItem key={query.id} className="!flex-col">
      <Layout.Column className="w-full">
        <Layout.Row className="justify-between items-start">
          <Layout.Row className="items-center">
            <Avatar className="h-8 w-8">
              <AvatarImage
                src={query.image || undefined}
                alt="organization logo"
              />
              <AvatarFallback>{query.name[0]}</AvatarFallback>
            </Avatar>
            <div className="font-heading text-xl">
              <Link
                href={{
                  pathname: "/[organization]",
                  query: { organization: query.id },
                }}
                className="hocus:underline"
              >
                {query.name}
              </Link>
            </div>
          </Layout.Row>
          <Badge className="capitalize" variant="secondary">
            {query.organizationUser?.role?.toLocaleLowerCase()}
          </Badge>
        </Layout.Row>
        <Layout.Row className="gap-x-6">
          <Layout.Row className="gap-x-2">
            <FolderOpen size={16} className="text-muted-foreground" />
            <Layout.Row className="text-sm gap-x-1">
              <p>{query.studies?.totalCount}</p>
              <p>Studies</p>
            </Layout.Row>
          </Layout.Row>
          <Layout.Row className="gap-x-2">
            <User size={16} className="text-muted-foreground" />
            <Layout.Row className="text-sm gap-x-1">
              <p>{query.organizationUsers.totalCount}</p>
              <p>Members</p>
            </Layout.Row>
          </Layout.Row>
        </Layout.Row>
      </Layout.Column>
    </List.ListItem>
  );
};

export const useMemberOrganizationListFragment = (
  queryRef: MemberOrganization_organizationListQuery$key
) => {
  return useFragment(
    graphql`
      fragment MemberOrganization_organizationListQuery on User {
        ...Forms_organizationCreateQuery
        organizationUsers(first: 999)
          @connection(key: "User_organizationUsers") {
          edges {
            node {
              organization {
                id
                ...MemberOrganization_organizationCardQuery
              }
            }
          }
        }
      }
    `,
    queryRef
  );
};

export const MemberOrganizationList = ({
  queryRef,
}: {
  queryRef: MemberOrganization_organizationListQuery$key;
}) => {
  const query = useMemberOrganizationListFragment(queryRef);
  return query.organizationUsers.edges.length ? (
    <>
      <Layout.Row className="justify-between">
        <Layout.Row>
          <Boxes size={32} className="text-muted-foreground stroke-[1.5]" />
          <h2>Your Organizations</h2>
        </Layout.Row>

        <OrganizationCreateDialog queryRef={query} />
      </Layout.Row>
      <List.List className="bg-background">
        {query.organizationUsers.edges.map(({ node: { organization } }) => (
          <MemberOrganizationCard
            data-test-id={`organization-card-${organization.id}`}
            key={organization.id}
            queryRef={organization}
          />
        ))}
      </List.List>
    </>
  ) : (
    <>
      <Layout.Row>
        <Globe size={32} className="text-muted-foreground stroke-[1.5]" />
        <h2>Create an Organization</h2>
      </Layout.Row>
      <Layout.Grid>
        <Card className="pt-2 col-span-12 sm:col-span-6">
          <CardHeader className="text-center">
            Please create a new organization.
          </CardHeader>
          <CardFooter className="justify-center">
            <OrganizationCreateDialog
              queryRef={query}
              buttonProps={{ variant: "primary" }}
            />
          </CardFooter>
        </Card>
        <div className="col-span-12 sm:col-span-6 px-4 prose">
          <p>
            Organizations allow you create and manage studies for yourself and
            others in your team.
          </p>
          <p>You can also be invited to existing organizations.</p>
        </div>
      </Layout.Grid>
    </>
  );
};
